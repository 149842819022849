import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/pro-regular-svg-icons/faArrowCircleLeft'

import { toast } from 'react-toastify'
import * as yup from 'yup'
import { Helmet } from 'react-helmet'

import { Heading, NotFoundMessage, UnauthorisedMessage } from '@peracto/peracto-ui'
import { GET_ONE, UPDATE, CREATE, useClient, getSchemaFromResource } from '@peracto/client'
import { useConfig } from '@peracto/peracto-config'
import cloneDeep from 'lodash/cloneDeep'

import { formatDataForAPI, formatDataForForm } from '../util'

const ShippingServiceEditContainer = ({ children }) => {
    return (
        <div className="form-container">
            <Heading name="Edit Shipping Service">
                <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                    <Link className="btn btn-outline-primary" to="/shipping-services">
                        <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
                        Back to Services
                    </Link>
                </div>
            </Heading>
            {children}
        </div>
    )
}

const ShippingServiceEdit = ({ ShippingServiceForm, location: { pathname } }) => {
    const { client, getResource } = useClient()
    const [loading, setLoading] = useState(true)
    const [unauthorised, setUnauthorised] = useState(false)
    const [notFound, setNotFound] = useState(false)
    const [redirect, setRedirect] = useState()

    const [service, setService] = useState()
    const config = useConfig()
    const { shippingServices } = config.get('features', {})

    const fetchShippingService = async () => {
        try {
            const { data, response } = await client(GET_ONE, 'shipping-services', {
                id: pathname,
            })

            const formattedData = formatDataForForm(data)

            setService(formattedData)
            setLoading(false)

            if (response.status === 404) {
                setRedirect('/shipping-services')
            }
        } catch (e) {
            console.error(e)
            if (e.status === 403) {
                setUnauthorised(true)
            }
            if (e.status === 404) {
                setNotFound(true)
            }

            setLoading(false)
            setRedirect('/shipping-services')
        }
    }

    useEffect(() => {
        fetchShippingService()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const schema = getSchemaFromResource(getResource('shipping-services')).shape({
        code: yup.string().required(),
        leadTimeDays:
            shippingServices.deliveryDays === true
                ? yup.string().required()
                : yup.string().notRequired(),
    })

    const onDuplicate = async data => {
        const dataClone = cloneDeep(data)

        const formattedData = formatDataForAPI(dataClone, true)

        try {
            const response = await client(CREATE, 'shipping-services', {
                data: formattedData,
            })

            window.location.href = response.data.id
        } catch (e) {
            console.error(e)
            toast.error(
                e?.error?.body?.hasOwnProperty('hydra:description')
                    ? e.error.body['hydra:description']
                    : 'Whoops, there was a problem...'
            )
        }
    }

    const onSubmit = async (data, actions) => {
        const dataClone = cloneDeep(data)

        try {
            const response = await client(UPDATE, 'shipping-services', {
                id: pathname,
                data: formatDataForAPI(dataClone),
            })

            if (response?.data?.violations?.length > 0) {
                // Display errors for invalid fields
                actions.setSubmitting(false)
                // eslint-disable-next-line
                response.data.violations.map(error => {
                    // eslint-disable-next-line
                    actions.setFieldError(error.propertyPath, error.message)
                })
            } else {
                actions.setSubmitting(false)
                toast.success('Shipping Service successfully updated!')
                const formattedData = formatDataForForm(response.data, pathname, shippingServices)
                setService(formattedData)
            }
        } catch (e) {
            console.error(e)
            toast.error(
                e?.error?.body?.hasOwnProperty('hydra:description')
                    ? e.error.body['hydra:description']
                    : 'Whoops, there was a problem...'
            )
            actions.setSubmitting(false)
        }
    }

    if (loading) {
        return (
            <ShippingServiceEditContainer>
                <div className="card">
                    <div className="card-body">Loading...</div>
                </div>
            </ShippingServiceEditContainer>
        )
    }

    if (unauthorised) {
        return <UnauthorisedMessage />
    }

    if (notFound) {
        return (
            <NotFoundMessage
                url="/shipping-services"
                message="The shipping service you're looking for could not be found"
                buttonLabel="Go to Services"
            />
        )
    }

    return (
        <ShippingServiceEditContainer>
            {redirect && <Redirect to={redirect} />}

            <Helmet>
                <title>{service?.name || 'Shipping Service'} | Edit | Peracto</title>
            </Helmet>

            <ShippingServiceForm
                values={service}
                onSubmit={onSubmit}
                onDuplicate={onDuplicate}
                schema={schema}
            />
        </ShippingServiceEditContainer>
    )
}

export default ShippingServiceEdit
