import React from "react";
import { ThemeProvider } from "styled-components";

import Peracto from "@peracto/peracto";
import Config from "@peracto/peracto-config";
import PeractoUser from "@peracto/peracto-user";
import PeractoCatalogue from "@peracto/peracto-catalogue";
import PeractoCommon from "@peracto/peracto-common";
import PeractoContent from "@peracto/peracto-content";
import PeractoLocations from "@peracto/peracto-locations";
import PeractoPromotions from "@peracto/peracto-promotions";

import { EditorTheme, AdminTheme, GlobalStyles } from "./theme";
import { Button } from "./components/Button/Button";

import { UserForm, UserAdd, UserEdit, UserList } from "./packages/User";
import {
  LocationsForm,
  LocationsAdd,
  LocationsEdit,
} from "./packages/Locations";
import {
  OrdersView,
  OrdersList,
  ProductAdd,
  ProductEdit,
  ProductForm,
  ShippingServiceAdd,
  ShippingServiceEdit,
  ShippingServiceForm
} from "./packages/Catalogue";

import blocks from "./blocks";
import { menu } from "./menu";

import compact from "lodash/compact";

const config = new Config({
  api: process.env.REACT_APP_API,
  algolia: {
    applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
    indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
  },
  modules: [
    PeractoUser({ UserForm, UserAdd, UserEdit, UserList }),
    PeractoCommon(),
    PeractoCatalogue({
      OrdersView,
      OrdersList,
      ProductAdd,
      ProductEdit,
      ProductForm,
      ShippingServiceAdd,
      ShippingServiceEdit,
      ShippingServiceForm
    }),
    PeractoLocations({ LocationsForm, LocationsAdd, LocationsEdit }),
    PeractoPromotions(),
    PeractoContent(),
  ],
  editorConfig: {
    theme: EditorTheme,
    colorOptions: compact(
      Object.entries(EditorTheme.colors.brand).map(([label, value]) => {
        // Filter white, because it's used by the default colours and we don't want to duplicate it.
        if (value === "#FFFFFF") return null;
        return { label, value };
      })
    ),
    headings: [
      {
        model: "heading1",
        title: "H1 - Small",
        class: "ck-heading_heading1",
        view: {
          name: "h1",
        },
      },
      {
        model: "heading1Large",
        title: "H1 - Large",
        class: "ck-heading_heading1",
        view: {
          name: "h1",
          classes: "heading1-large",
        },
        converterPriority: "high",
      },
      {
        model: "heading3Paragraph",
        title: "Heading 3 - Paragraph",
        class: "ck-heading_heading3",
        view: {
          name: "h3",
          classes: "heading3-paragraph",
        },
        converterPriority: "high",
      },
    ],
    locales: ["en_GB"],
    blocks,
    inputTypes: {
      Button,
    },
    stylesheets: [
      "https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap",
    ],
    globalStyles: GlobalStyles,
  },
  features: {
    shippingServices: {
      deliveryDays: true,
      conditions: true,
    },
    products: {
      bulkPricing: true,
      exportProductData: true,
      productVariants: true,
    },
    user: {
      companySettings: true,
    },
    content: {
      indexContent: true,
    },
  },
  menu,
});

const App = () => (
  <ThemeProvider theme={AdminTheme}>
    <Peracto config={config} />
  </ThemeProvider>
);

export default App;
