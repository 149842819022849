export const formatDataForAPI = (data, isDuplicate = false) => {
    if (isDuplicate) {
        data.code = `copy-of-${data.code}`
        data.name = `Copy of ${data.name}`
        delete data['@id']
        delete data['@context']
        delete data['@type']
        delete data['id']
        delete data['originId']

        if (data?.costs?.length > 0) {
            data.costs = data.costs.map(costItem => {
                delete costItem['@id']
                delete costItem['@type']
                return {
                    ...costItem,
                }
            })
        }

        if (data?.postcodeRules?.length > 0) {
            data.postcodeRules = data.postcodeRules.map(rule => {
                delete rule['@id']
                delete rule['@type']
                return {
                    ...rule,
                }
            })
        }

        if (data?.postcodeCosts?.length > 0) {
            data.postcodeCosts = data.postcodeCosts.map(cost => {
                delete cost['@id']
                delete cost['@type']
                return {
                    ...cost,
                }
            })
        }
    }

    data.exclusion = data?.postcodeRulesMode === 'excluded'

    if (data.postcodeRulesMode === 'all') {
        data.postcodeRules = []
    }

    if (data.displayOrder) {
        data.displayOrder = parseInt(data.displayOrder)
    } else {
        delete data.displayOrder
    }

    if (data.cutoffTime) {
        if (data.cutoffTime.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)) {
            data.cutoffTime = `${data.cutoffTime}:00`
        }
    } else {
        data.cutoffTime = null
    }

    if (data?.costs?.length > 0) {
        data.costs = data.costs.map(costItem => {
            return {
                ...costItem,
                cost: costItem.cost ? Math.round(parseFloat(costItem.cost) * 100) : 0,
            }
        })
    }

    if (data?.weightCost && data?.weightCost > 0) {
        data.weightCost = Math.round(data.weightCost * 100)
    }

    if (data?.postcodeRules?.length > 0) {
        data.postcodeRules = data.postcodeRules.map(rule => {
            return {
                ...rule,
                districtStart: parseInt(rule.districtStart),
                districtEnd: parseInt(rule.districtEnd),
            }
        })
    }

    if (data?.conditions?.length > 0) {
        data.conditions = data.conditions.map(condition => ({
            ...condition,
            value:
                condition.condition === 'basket.subtotal'
                    ? Math.round(parseFloat(condition.value) * 100)
                    : condition.value,
        }))
    }

    if (data?.postcodeCosts?.length > 0) {
        data.postcodeCosts = data.postcodeCosts.map(cost => ({
            ...cost,
            districtStart: cost.districtStart > -1 ? parseInt(cost.districtStart) : null,
            districtEnd: cost.districtEnd > -1 ? parseInt(cost.districtEnd) : null,
            cost: cost.cost ? Math.round(parseFloat(cost.cost) * 100) : 0,
        }))
    }

    return { ...data }
}

export const formatDataForForm = (data, pathname, shippingServices) => {
    const requireShippingId = ['costs', 'dateExclusions', 'postcodeRules']

    // Add the current shipping service ID to the properties that require it
    requireShippingId.forEach(
        type =>
            (data[type] = data[type].map(data => ({
                ...data,
                shippingService: pathname,
            })))
    )

    data.postcodeRulesMode =
        data?.postcodeRules?.length > 0
            ? data.exclusion === true
                ? 'excluded'
                : 'included'
            : 'all'

    if (
        (!data.deliveryDays ||
            (Array.isArray(data.deliveryDays) && data.deliveryDays.length === 0)) &&
        shippingServices?.deliveryDays === true
    ) {
        data.deliveryDays = {
            day_0: false,
            day_1: false,
            day_2: false,
            day_3: false,
            day_4: false,
            day_5: false,
            day_6: false,
        }
    }

    if (data?.costs?.length > 0) {
        data.costs = data.costs.map(costItem => {
            return {
                ...costItem,
                cost: (costItem.cost / 100).toFixed(2),
            }
        })
    }

    if (data?.weightCost && data?.weightCost > 0) {
        data.weightCost = (parseInt(data.weightCost) / 100).toFixed(2)
    }

    if (!data.leadTimeDays && shippingServices?.deliveryDays === true) {
        data.leadTimeDays = 0
    }

    if (data?.postcodeCosts?.length > 0) {
        data.postcodeCosts = data.postcodeCosts.map(cost => ({
            ...cost,
            cost: cost.cost ? (parseInt(cost.cost) / 100).toFixed(2) : 0,
        }))
    }

    if (data.conditions?.length > 0) {
        data.conditions = data.conditions.map(condition => ({
            ...condition,
            value:
                condition.condition === 'basket.subtotal'
                    ? (parseInt(condition.value) / 100).toFixed(2)
                    : condition.value,
        }))
    }

    return { ...data }
}
