import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/pro-regular-svg-icons/faArrowCircleLeft'

import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'
import * as yup from 'yup'

import { Heading } from '@peracto/peracto-ui'
import { CREATE, useClient, getSchemaFromResource } from '@peracto/client'
import { useConfig } from '@peracto/peracto-config'

import { MODE_ADD } from '../ShippingServiceForm'
import { formatDataForAPI } from '../util'
import cloneDeep from 'lodash/cloneDeep'

const ShippingServiceAdd = ({ ShippingServiceForm }) => {
    const { client, getResource } = useClient()
    const [redirect, setRedirect] = useState()

    const config = useConfig()
    const { shippingServices } = config.get('features', {})

    const schema = getSchemaFromResource(getResource('shipping-services')).shape({
        code: yup.string().required(),
        leadTimeDays:
            shippingServices.deliveryDays === true
                ? yup.string().required()
                : yup.string().notRequired(),
    })

    const onSubmit = async (data, actions) => {
        const dataClone = cloneDeep(data)
        try {
            const response = await client(CREATE, 'shipping-services', {
                data: formatDataForAPI(dataClone),
            })

            setRedirect(response.data.id)
            toast.success('Shipping Service successfully added!')
            actions.setSubmitting(false)
        } catch (e) {
            if (e?.error?.body?.violations?.length > 0) {
                // Display errors for invalid fields
                actions.setSubmitting(false)
                // eslint-disable-next-line
                e.error.body.violations.map(error => {
                    // eslint-disable-next-line
                    actions.setFieldError(error.propertyPath, error.message)
                })
            }
            console.error(e)
            toast.error(
                e?.error?.body?.hasOwnProperty('hydra:description')
                    ? e.error.body['hydra:description']
                    : 'Whoops, there was a problem...'
            )
            actions.setSubmitting(false)
        }
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <>
            <Helmet>
                <title>Shipping Services | Add | Peracto</title>
            </Helmet>

            <div className="form-container">
                <Heading name="Add Shipping Service">
                    <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                        <Link className="btn btn-outline-primary" to="/shipping-services">
                            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
                            Back to Services
                        </Link>
                    </div>
                </Heading>
                <ShippingServiceForm
                    onSubmit={onSubmit}
                    mode={MODE_ADD}
                    schema={schema}
                    values={{ postcodeRulesMode: 'all', leadTimeDays: 0, cutoffTime: null }}
                />
            </div>
        </>
    )
}

export default ShippingServiceAdd
